const html = document.querySelector('html')
const body = document.querySelector('body')
const menuToggler = document.querySelector('#menu-toggler')

menuToggler.addEventListener('click', bodyScroll)

function bodyScroll () {
  if (menuToggler.checked) {
    html.classList.add('noscroll')
    body.classList.add('noscroll')
  } else {
    html.classList.remove('noscroll')
    body.classList.remove('noscroll')
  }
}